import React from 'react'

import GoogleWordmark from '@/assets/logos/google-wordmark.svg'
import AirbnbWordmark from '@/assets/logos/airbnb-wordmark.svg'
import AffirmWordmark from '@/assets/logos/affirm-wordmark.svg'
import FacebookWordmark from '@/assets/logos/facebook-wordmark.svg'
import UberWordmark from '@/assets/logos/uber-wordmark.svg'
import RobinhoodWordmark from '@/assets/logos/robinhood-wordmark.svg'

import './index.scss'

export default () => {
  return (
    <div className='companies'>
      <p className='description'>We help data scientists land jobs at top tech companies like:</p>
      <div className='container'>
        <div className='company google'>
          <img className='logo' src={GoogleWordmark} alt = 'Data science interview questions Google' />
        </div>
        <div className='company airbnb'>
          <img className='logo' src={AirbnbWordmark} alt = 'Data science interview questions Airbnb' />
        </div>
        <div className='company affirm'>
          <img className='logo' src={AffirmWordmark} alt = 'Data science interview questions Affirm' />
        </div>
        <div className='company facebook'>
          <img className='logo' src={FacebookWordmark} alt = 'Data science interview questions Facebook' />
        </div>
        <div className='company uber'>
          <img className='logo' src={UberWordmark} alt = 'Data science interview questions Uber' />
        </div>
        <div className='company robinhood'>
          <img className='logo' src={RobinhoodWordmark} alt = 'Data science interview questions Robinhood' />
        </div>
      </div>
    </div>
  );
}
