import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { initializeAdTracking } from '@/analytics';
import Spinner from '@/components/Spinner';
import marked from 'marked'

import getPosts from '@/posts'

import './index.scss';
import 'katex/dist/katex.min.css'
import renderMathInElement from 'katex/dist/contrib/auto-render'

export default class Post extends Component {
  state = {
    loading: true,
    post: null
  }
  setRef = (ref) => {
    this.intervalId = setInterval(() => {
      if (ref != null) {
        renderMathInElement(ref)
      }
    }, 1000)
  }
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    initializeAdTracking(this.props.location.pathname);
    const slug = this.props.match.params.slug

    const posts = await getPosts()

    const post = posts.find((post) => {
      return post.slug === slug
    })

    this.setState({
      post,
      loading: false,
    })
  }
  createMarkup = (content) => {
    const html = marked(content)
    return { __html: html };
  }
  render() {
    if (this.state.loading) {
      return <Spinner />
    } else if (this.state.post != null) {
      return (
        <div className = 'blogContainer'>
          <div className = 'post'>
            <h2 className = 'postTitle'>{this.state.post.title}</h2>
            <img className = 'postImage' src = {this.state.post.image} alt = 'Data Science Prep, the best interview prep for data science jobs.'/>
            <div className = 'problemAndSolutionContainer' dangerouslySetInnerHTML = {this.createMarkup(this.state.post.content)} ref={this.setRef} />
            <div className = 'postTextContainer'>
              <p className = 'postText'>Are you interviewing for data science jobs or are you trying to hone your data science skills? Check out our newsletter, <NavLink smooth to = '/#subscribe' className = 'linkText'>Data Science Prep</NavLink>, to get data science interview questions straight to your inbox.</p>
            </div>
            <div className = 'blogCTAContainer'>
              <h1 className = 'blogCTAHeader'>Ace your data science interview</h1>
              <h2 className = 'blogCTASubtitle'>Start thinking like a data scientist by solving real interview questions in your inbox!</h2>
              <div>
                <NavLink smooth to = '/'>
                  <button className = 'blogCTA'>Subscribe</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className = 'blogContainer'>
          <div className = 'post'>
            <div className = 'noPost'>
              <h1 className = 'noPostText'>There doesn't seem to be a post here! <NavLink to = '/blog' className = 'linkText'>Go to blog</NavLink></h1>
            </div>
            <div className = 'blogCTAContainer'>
              <h1 className = 'blogCTAHeader'>Ace your data science interview</h1>
              <h2 className = 'blogCTASubtitle'>Start thinking like a data scientist by solving data science problems in your inbox.</h2>
              <div>
                <NavLink smooth to = '/'>
                  <button className = 'blogCTA'>Subscribe</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
