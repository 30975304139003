import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { initializeAdTracking } from '@/analytics';
import Checkmark from '@/assets/iconography/checkmark.svg';
import '@/main.scss';

export default class UpgradeSuccessful extends Component {
  state = {
    copied: false,
    shareLink: 'https://datascienceprep.com/?utm_source=share&utm_medium=link&utm_campaign=friend_referral'
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
  }
  onCopy = () => {
    this.setState({
      copied: true
    });
    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1000);
  }
  render() {
    return (
      <div className = 'mainContainer'>
        <div className = 'verifyContainer'>
          <div className = 'verifySection'>
            <img src = {Checkmark} className = 'checkmark' alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
            <h1 className = 'verifyTitle'>You've successfully upgraded your subscription!</h1>
          </div>
          <p className = 'verifyText'>You'll start receiving solutions along with your questions.</p>


          <div className = 'separator' />

          <div className = 'verifySection'>
            <h3 className = 'verifyHeader'>Share us with your friends</h3>
            <p className = 'verifyText'>Help your friends and colleagues ace their data science interview.</p>
          </div>
          {
            this.state.copied ?
            (
              <div className = 'shareContainer copied'>
                <span className = 'shareLink copied'>https://datascienceprep.com</span>
                <img className = 'shareCTA copied' src = {Checkmark} alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
              </div>
            ) :
            (
              <div className = 'shareContainer'>
                <span className = 'shareLink'>https://datascienceprep.com</span>
                <CopyToClipboard onCopy = {this.onCopy} text = {this.state.shareLink}>
                  <span className = 'shareCTA'>Copy</span>
                </CopyToClipboard>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
