import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Checkmark from '@/assets/iconography/checkmark.svg';

import Spinner from '@/components/Spinner'

import '@/main.scss';

class FormSetPassword extends Component {
  state = {
    newPassword: null,
    confirmPassword: null,
    updating: false,
    error: null,
  }
  setPassword = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      updating: true,
    });
    if(!this.state.newPassword || !this.state.confirmPassword) {
      this.setState({
        error: 'Please enter all password fields',
        updating: false,
      });
    } else if(this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        error: 'New passwords don\'t match!',
        updating: false,
      });
    } else if(this.state.newPassword.length < 6) {
      this.setState({
        error: 'Your new password must be at least 6 characters long',
        success: false,
        updating: false
      })
    } else {
      const UPDATE_RESPONSE = await fetch(`/api/users/password/${this.props.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
        },
        body: JSON.stringify({
          newPassword: this.state.newPassword,
          confirmPassword: this.state.confirmPassword
        })
      });
      const UPDATE_DATA = await UPDATE_RESPONSE.json();

      if(UPDATE_DATA.auth) {
        this.setState({
          success: true,
          error: null,
          updating: false,
        });
        await this.props.handleLogin(UPDATE_DATA.authToken);
        setTimeout(() => {
          this.props.history.push('/dashboard');
        }, 1500);
      } else {
        this.setState({
          error: UPDATE_DATA.error,
          updating: false,
        });
      }
    }
  }
  render() {
    if(this.state.updating){
      return <Spinner />
    }
    if(this.state.success){
      return (
        <div className = 'mainContainer'>
          <div className = 'verifyContainer'>
            <div className = 'verifySection'>
              <img src = {Checkmark} className = 'checkmark' alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
              <h1 className = 'verifyTitle'>Your password has been set!</h1>
            </div>
            <div className = 'verifySection'>
              <p className = 'verifyText'>Redirecting you to your personal dashboard...</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form onSubmit = {this.setPassword}>
        <div className = 'formSection'>
          <label className = 'formLabel'>New password</label>
          <input
            onChange = {(e) => this.setState({ newPassword : e.target.value })}
            type = 'password'
            placeholder = 'New password (at least 6 characters)'
            value = {this.state.newPassword}
            className = 'formInput'
          />
          <label className = 'formLabel'>Confirm new password</label>
          <input
            onChange = {(e) => this.setState({ confirmPassword : e.target.value })}
            type = 'password'
            placeholder = 'Confirm password'
            value = {this.state.confirmPassword}
            className = 'formInput'
          />
        </div>
        <div className = 'formSection'>
          <span className = 'error'>{this.state.error}</span>
          <button>Set Password</button>
        </div>
      </form>
    );
  }
}

export default withRouter(FormSetPassword);
