import React, { Component } from 'react';
import FacebookWordmark from '@/assets/logos/facebook-wordmark.svg';
import ProblemAndSolution from '@/components/ProblemAndSolution';

export default class SampleProblem extends Component {
  render() {
    return (
      <div className = 'mainSection'>
        <div className = 'mainSectionColumn'>
          <h3 className='serif'>Sample Interview Question</h3>
          <h2>{this.props.title}</h2>
          <div className = 'subtitle'>
            <span className = 'subtitleText'>Asked by </span>
            <img src = {FacebookWordmark} className = 'facebookWordmark' alt = 'Facebook data science interview question' />
          </div>
          <ProblemAndSolution problem = {this.props.problem} solution = {this.props.solution} />
        </div>
      </div>
    );
  }
}
