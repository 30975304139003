import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import 'animate.css';
import './index.css';

export default class SubscribeModal extends Component {
  render() {
    return (
      <div className = 'modalContainer animated fadeIn faster'>
        {
          this.props.success ?
          (
            <div className = 'modalSection'>
              <p className = 'modalTitle'>Success!</p>
              <p className = 'modalText'>Just one thing: to start receiving problems, we need to verify your email address.</p>
              <p className = 'modalText'>Could you <strong>please check your email for a verification link?</strong> Thanks!</p>
              <p className = 'modalText'>Thanks for subscribing! We hope you enjoy our data science problems.</p>
              <NavLink to = '/signed-up' className = 'navButton'>
                <button className = 'modalButton'>I verified my email address!</button>
              </NavLink>
            </div>
          ) :
          (
            <div className = 'modalSection'>
              <p className = 'modalTitle'>Error!</p>
              <p className = 'modalText'>{this.props.error}</p>
              <p className = 'modalText'>Otherwise, please contact us at <a href = 'mailto:team@datascienceprep.com' className = 'linkText'>team@datascienceprep.com</a> and let us know what's going on.</p>
              {
                this.props.error === 'You are already enrolled to Data Science Prep!' ?
                (
                  <div>
                    <button className = 'modalButton' onClick = {() => this.props.resubscribe()}>Resend Verification Email</button>
                    <button className = 'modalButton' onClick = {() => this.props.closeModal()}>Close</button>
                  </div>
                ) : this.props.error === 'You unsubscribed on this email! Would you like to resubscribe?' ?
                (
                  <div>
                    <button className = 'modalButton' onClick = {() => this.props.resubscribe()}>Resubscribe</button>
                    <button className = 'modalButton' onClick = {() => this.props.closeModal()}>Close</button>
                  </div>
                ) :
                (
                  <button className = 'modalButton' onClick = {() => this.props.closeModal()}>Close</button>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}
