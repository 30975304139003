import React, { Component } from 'react';
import './index.scss';

export default class CouponNotification extends Component {
  render() {
    if (this.props.coupon == null) {
      return null
    }
    return (
      <div className='coupon-notification-success'>
        <h3>Success!</h3>
        <p>Congrats! The discount code <b>{this.props.coupon.code}</b> you entered is valid. Note that the discount price below is only valid for the first month (for monthly) or year (for yearly) of subscription. Enjoy your discount. :)</p>
      </div>
    );
  }
}
