import React, { Component } from 'react'
import Spinner from '@/components/Spinner'

import './index.css'

export default class SurveyForm extends Component {
  state = {
    submitSurvey: false
  }
  submitSurvey = () => {
    this.setState({ status: 'submitting' })
  }
  render() {
    if (this.state.status === 'submitting'){
      return <Spinner />
    }
    if (this.state.status === 'success'){
      return (
        <div />
      );
    }
    return (
      <div>
        <form>
          <button><span>Submit</span></button>
        </form>
      </div>
    );
  }
}
