import React, { Component } from 'react';
import './index.css';

export default class UpdateCardModal extends Component {
  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <div className = 'modalContainer'>
        <div className = 'modalSection'>
          <p className = 'modalTitle'>Success!</p>
          <p className = 'modalText'>You've successfully updated your credit card.</p>
          <button className = 'modalButton' onClick={this.props.closeModal}>Close</button>
        </div>
      </div>
    );
  }
}
