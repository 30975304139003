import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import marked from 'marked'
import './index.scss';

export default class PostCard extends Component {
  state = {
    text: null
  }
  componentDidMount = async () => {
    const text = await this.createMarkup(this.props.post.content)
    this.setState({
      text: text.__html,
      loading: false
    })
  }
  createMarkup = (content) => {
    const html = marked(content)
    return { __html: html };
  }
  render() {
    return (
      <div className = 'postCardContainer'>
        <NavLink to = {'/blog/' + this.props.post.slug} className = 'cardTitle'>
          <h1 className = 'postTitle'>{this.props.post.title}</h1>
        </NavLink>
        <img
          className = 'postImage'
          src = {this.props.post.image}
          alt = 'Daily Product Prep, the best interview prep for product manager jobs. Solve product problems every day.'
        />
        <HTMLEllipsis
          className = 'clampLineText'
          unsafeHTML = {this.state.text}
          maxLine = {5}
          ellipsis = '...'
        />
        <div>
          <NavLink to = {'/blog/' + this.props.post.slug} className = 'linkText'>... read more</NavLink>
        </div>
      </div>
    );
  }
}
