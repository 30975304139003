import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.scss';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

import queryString from 'query-string'
import cookieManager from '@/lib/cookieManager'
import { STORAGE_KEY } from '@/constants';

import Splash from '@/pages/Splash';
import Upgrade from '@/pages/Upgrade';
import UpgradeSuccessful from '@/pages/UpgradeSuccessful';
import Verification from '@/pages/Verification';
import Unsubscribe from '@/pages/Unsubscribe';
import SignedUp from '@/pages/SignedUp';
import Solution from '@/pages/Solution';
import Survey from '@/pages/Survey';
import Post from '@/pages/Post';
import Blog from '@/pages/Blog';
import TermsOfService from '@/pages/TermsOfService';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import NotFound from '@/pages/NotFound';
import ResetPassword from '@/pages/ResetPassword';
import SetPassword from '@/pages/SetPassword';
import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
import Dashboard from '@/pages/Dashboard';
import Settings from '@/pages/Settings';

export default class App extends Component {
  state = {
    user: null,
    type: null,
  }
  componentDidMount = async () => {
    try {
      await this.updateUser();
      const parsed = queryString.parse(window.location.search)
      if (parsed != null && parsed.ref !== null) {
        cookieManager.setRef(parsed.ref)
      }
    } catch (e) {
      console.log('error:', e)
    }
  }
  updateUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    if(DEMO_TOKEN){
      const AUTH_RESPONSE = await fetch(`/api/users/auth/${DEMO_TOKEN}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      });
      const AUTH_DATA = await AUTH_RESPONSE.json();
      if(AUTH_DATA.auth){
        this.setState({
          user: AUTH_DATA.user,
          type: AUTH_DATA.user.type,
        });
      }
      else {
        this.setState({
          error: AUTH_DATA.error,
          user: null,
        });
      }
    }
    else {
      this.setState({
        user: null,
        type: null,
      });
    }
  }
  handleLogin = async (token) => {
    await localStorage.setItem(STORAGE_KEY, token);
    await this.updateUser();
  }
  resetToken = async () => {
    await localStorage.removeItem(STORAGE_KEY);
    await this.updateUser();
  }
  FlexRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render = {props =>
          (
            <Component {...props} {...rest}/>
          )
        }
      />
    );
  }
  FreeRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render = {props =>
          this.state.type === 'premium' ? (
            <Redirect
              to = {{
                pathname: "/dashboard",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component {...props} {...rest}/>
          )
        }
      />
    );
  }
  render() {
    return (
      <BrowserRouter>
        <Helmet>
          <title>Data Science Prep</title>
          <meta name = 'description' content = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
        </Helmet>

        <div className = 'appContainer'>
          <Header globals = {this.state} />
          <Switch>
            <this.FreeRoute exact path = '/' component = {Splash} />
            <this.FreeRoute exact path = '/upgrade' component = {Upgrade} />
            <Route exact path = '/upgrade/success' component = {UpgradeSuccessful} />
            <Route exact path = '/solution/:problemNumber' component = {Solution} />
            <Route exact path = '/survey/:surveyNumber' component = {Survey} />
            <Route exact path = '/signed-up' component = {SignedUp} />
            <Route exact path = '/verification' component = {Verification} />
            <Route exact path = '/unsubscribe' component = {Unsubscribe} />
            <Route exact path = '/privacy' component = {PrivacyPolicy} />
            <this.FlexRoute exact path = '/settings' component = {Settings} globals = {this.state} />
            <Route exact path = '/blog' component = {Blog} />
            <Route exact path = '/blog/:slug' component = {Post} />
            <Route exact path = '/terms-of-service' component = {TermsOfService} />
            <Route exact path = '/reset-password' component = {ResetPassword} />
            <this.FlexRoute exact path = '/set-password' component = {SetPassword} handleLogin = {this.handleLogin} />
            <this.FreeRoute exact path = '/login' component = {Login} handleLogin = {this.handleLogin} />
            <this.FlexRoute exact path = '/logout' component = {Logout} resetToken = {this.resetToken} />
            <this.FlexRoute exact path = '/dashboard' component = {Dashboard} globals = {this.state} resetToken = {this.resetToken} />
            <Route component = {NotFound} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
