import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import ReactTooltip from 'react-tooltip'

import Spinner from '@/components/Spinner'

import './index.scss';

export default class Mapper extends Component {
  state = {
    mapping: true
  }
  componentDidMount = async () => {
    const problemNumber = Math.max(this.props.user.nextProblem, 30)
    const res = await fetch(`/api/problems/until/${problemNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const { titles } = await res.json()
    this.setState({
      titles,
      mapping: false,
    });
  }
  render() {
    if(this.state.mapping){
      return <Spinner />
    }
    if(this.props.type === 'problemTitles'){
      return (
        <div className = 'mapContainer'>
          <ReactTooltip />
          {
            this.state.titles.map(title => {
              if (title.problemNumber < this.props.user.nextProblem) {
                return (
                  <NavLink to = {'/solution/' + title.problemNumber} className = 'mapTitle'>
                    {title.problemNumber}. {title.title}
                  </NavLink>
                );
              } else {
                return <div className = 'mapTitle locked' data-tip="You don't have access to this problem right now!">
                  {title.problemNumber}. {title.title}
                </div>
              }
            })
          }
        </div>
      );
    }
    return <div />
  }
}
