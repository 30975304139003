import React, { Component } from 'react'

import { initializeAdTracking } from '@/analytics'

import FormLogin from '@/components/FormLogin'

import '@/main.scss'

export default class Login extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>Login</h3>
            <h2>Join the conversation as a premium user.</h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <div className = 'mainSectionColumn'>
            <FormLogin handleLogin = {this.props.handleLogin} />
          </div>
        </div>
      </div>
    );
  }
}
