import React from 'react';

export default () => {
  return (
    <div className = 'mainSection'>
      <div className = 'mainSectionColumn'>
        <h3 className='serif'>How It Works</h3>
        <div className = 'stepsContainer'>
          <div className = 'step'>
            <div className = 'stepSection'>
              <h2 className = 'stepHeader'><span className = 'stepNumber'>1</span>We Write Problems</h2>
            </div>
            <p className = 'stepText'>Get tailored problems from our experts who have interviewed at top companies.</p>
          </div>
          <div className = 'step'>
            <div className = 'stepSection'>
              <h2 className = 'stepHeader'><span className = 'stepNumber'>2</span>You Solve Them</h2>
            </div>
            <p className = 'stepText'>Solve the problem in your inbox before you get the solutions.</p>
          </div>
          <div className = 'step'>
            <div className = 'stepSection'>
              <h2 className = 'stepHeader'><span className = 'stepNumber'>3</span>We Send The Solution</h2>
              <span className = 'premium'>PREMIUM</span>
            </div>
            <p className = 'stepText'>Verify your work and get better at problem solving until you land the job!</p>
          </div>
        </div>
      </div>
    </div>
  );
}
