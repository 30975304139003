import React from 'react';

export default () => {
  return (
    <div className = 'mainSection'>
      <div className = 'mainSectionColumn'>
        <h3 className='serif'>Need to cram or want solutions instantly? (New)</h3>
        <p>Since many folks have been asking about cramming or instant access, <strong>you can now do so</strong> with Volume I or II which are ebooks with 100 in-depth questions and solutions:
        <a href= 'https://www.fatfreecartpro.com/i/zl39'> get Volume I here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/zs1x'> Volume II here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/106b4'> Volume III here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/zs1y'> Volume I and II here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/106b7'> Volume I and III here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/106b8'> Volume II and III here </a> or
        <a href= 'https://www.fatfreecartpro.com/i/106b9'> Volume I, II, III here. </a>
        Otherwise, subscription details below!
        </p>
      </div>
    </div>
  );
}
