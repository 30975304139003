import React, { Component } from 'react'
import marked from 'marked'
import { NavLink } from 'react-router-dom'
import MdListBox from 'react-ionicons/lib/MdListBox'
import IosUnlock from 'react-ionicons/lib/IosUnlock'
import IosPersonAdd from 'react-ionicons/lib/IosPersonAdd'
import BlurredImage from '@/assets/images/blurred_solution.png'
import renderMathInElement from 'katex/dist/contrib/auto-render'

import ReferralModal from '@/components/ReferralModal'
import 'katex/dist/katex.min.css'
import './index.css'

function getMarkdownText(text) {
  const rawMarkup = marked(text, { sanitize: false });
  return { __html: rawMarkup };
}

const SolutionButton = ({onClick}) => {
  return <button onClick = {onClick}><MdListBox /><span> Show Solution</span></button>
}

class Problem extends Component {
  render () {
    const { problem } = this.props
    if(problem == null){
      return <div />
    }
    return (
      <div className = 'problem qaSection'>
        <div className = 'solutionText' dangerouslySetInnerHTML = {getMarkdownText(problem)} />
      </div>
    );
  }
}

class Solution extends Component {
  state = {
    showReferralModal: false
  }
  closeModal = () => {
    this.setState({ showReferralModal: false })
  }
  render () {
    const { solution, email, problemNumber } = this.props

    if (solution == null) {
      if (problemNumber > 5) {
        return <div className='solution-cta'>
          <NavLink to="/upgrade" className='solution-cta'>
            <img className='blurred' src={BlurredImage} alt = 'Data Science Prep data science interview solutions blurred' />
            <button><IosUnlock /><span> Upgrade to Premium for detailed explanations</span></button>
          </NavLink>
        </div>
      } else {
        // Only show referral option for first 5 questions
        return <div className='solution-cta'>
          { this.state.showReferralModal ? <ReferralModal email={email} problemNumber={problemNumber} closeModal={this.closeModal} /> : null }
          <NavLink to="/upgrade" className='solution-cta'>
            <img className='blurred' src={BlurredImage} alt = 'Data Science Prep data science interview solutions blurred' />
            <button><IosUnlock /><span> Upgrade to Premium for detailed explanations</span></button>
          </NavLink>
          <h3>or</h3>
          <button onClick={() => this.setState({ showReferralModal: true })}><IosPersonAdd /><span> Refer a friend to unlock this solution!</span></button>
        </div>
      }
    }

    return <div className = 'qaSection'>
      <div className = 'solutionText' dangerouslySetInnerHTML = {getMarkdownText(solution)} />
    </div>
  }
}

export default class ProblemAndSolution extends Component {
  state = {
    showSolution: false
  }
  setRef = (ref) => {
    this.intervalId = setInterval(() => {
      if (ref != null) {
        renderMathInElement(ref)
      }
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
  }
  showSolution = () => {
    this.setState({ showSolution: true })
  }
  render() {
    const { problem, solution, email, problemNumber } = this.props
    return (
      <div className = 'problemAndSolutionContainer' ref={this.setRef}>
        <Problem problem = {problem} />
        <div className = 'solution'>
          <h3 className = 'serif'>Solution</h3>
          <div className='solutionSection'>
            {this.state.showSolution ? <Solution solution={solution} email={email} problemNumber={problemNumber} /> : <SolutionButton onClick={this.showSolution} />}
          </div>
        </div>
      </div>
    );
  }
}
