import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';
import CheckoutForm from '@/components/CheckoutForm';

export default class Checkout extends Component {
  render() {
    return (
      <Elements>
        <CheckoutForm
          error = {this.props.error}
          closeModal = {this.props.closeModal}
          onCheckout = {this.props.onCheckout}
          initialEmail = {this.props.initialEmail}
          subtitle = {this.props.subtitle}
          buttonText = {this.props.buttonText}
          hideEmailField = {this.props.hideEmailField}
        />
      </Elements>
    );
  }
}
