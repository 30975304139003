import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Checkmark from '@/assets/iconography/checkmark.svg';

import IosShareAlt from 'react-ionicons/lib/IosShareAlt'
import 'animate.css'
import './index.css'

export default class ReferralModal extends Component {
  state = {
    loading: true,
    error: null,
    code: null,
    copied: false
  }
  async componentDidMount() {
    const { email, problemNumber } = this.props
    const res = await fetch(`/api/referrals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        problemNumber
      })
    })
    const body = await res.json()
    if (body.error) {
      this.setState({
        loading: true,
        error: body.error
      })
    } else {
      this.setState({
        loading: false,
        code: body.code
      })
    }
  }
  onCopy = () => {
    this.setState({
      copied: true
    });
    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1000);
  }
  referralLink = () => {
    if (this.state.code == null) {
      return `https://datascienceprep.com`
    }
    return `https://datascienceprep.com/?ref=${this.state.code}`
  }
  render() {
    if (this.state.error) {
      return <div className = 'modalContainer animated fadeIn faster'>
        <div className = 'modalSection'>
          <p className = 'modalTitle'>Error!</p>
          <p className = 'modalText'>Sorry, something went wrong. Please try again or contact team@datascienceprep.com.</p>
        </div>
      </div>
    }
    return (
      <div className = 'modalContainer animated fadeIn faster'>
        <div className = 'modalSection referral-modal'>
          <IosShareAlt />
          <h1 className = 'modalTitle'>Share us with your friends</h1>
          <p className = 'modalText'>Once your friend or colleague signs up and verifies their email from this link, you'll receive an in-depth solution to this problem in your inbox.</p>
          {
            this.state.copied ?
            (
              <div className = 'shareContainer copied'>
                <span className = 'shareLink copied'>{this.referralLink()}</span>
                <img className = 'shareCTA copied' src = {Checkmark} alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
              </div>
            ) :
            (
              <div className = 'shareContainer'>
                <span className = 'shareLink'>{this.referralLink()}</span>
                <CopyToClipboard onCopy = {this.onCopy} text = {this.referralLink()}>
                  <span className = 'shareCTA'>Copy</span>
                </CopyToClipboard>
              </div>
            )
          }
          <button onClick={this.props.closeModal}>Close</button>
        </div>
      </div>
    );
  }
}
