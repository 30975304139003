import React, { Component } from 'react';
import DotLoader from '@/components/DotLoader';
import './index.css';

export default class Spinner extends Component {
  render() {
    return (
      <div className = 'stateContainer'>
        <DotLoader />
      </div>
    );
  }
}
