const POSTS = [
  {
    title: 'How To Get A Job In Data Science',
    slug: 'how-to-get-a-job-in-data-science',
    image: 'https://i.imgur.com/7NWJlcg.jpg',
    content: require('./how-to-get-a-job-in-data-science.md'),
  },
  {
    title: 'Five Tips for Data Science Interviews',
    slug: 'five-tips-for-data-science-interviews',
    image: 'https://miro.medium.com/max/1400/1*5rXejhcKLorK7JiXnQH-tg.jpeg',
    content: require('./five-tips-for-data-science-interviews.md'),
  },
  {
    title: 'Machine Learning in Data Science Interviews',
    slug: 'ml-in-for-data-science-interviews',
    image: 'https://miro.medium.com/max/1400/1*vl8X3slbEpjvxe19k8xwHQ.jpeg',
    content: require('./ml-in-data-science-interviews.md'),
  },
  {
    title: 'Statistics Guide for Data Science Interivews',
    slug: 'stat-guide-for-data-science-interviews',
    image: 'https://miro.medium.com/max/1400/1*3la1_aTR3DE-cY5ifY5_yw.jpeg',
    content: require('./stat-guide-data-science-interviews.md'),
  },
  {
    title: 'SQL Guide for Data Science Interivews',
    slug: 'sql-guide-for-data-science-interviews',
    image: 'https://miro.medium.com/max/1400/1*fxixiwnkFlcNnECvNyhyTQ.jpeg',
    content: require('./sql-guide-data-science-interviews.md'),
  },
  {
    title: 'Coding Guide for Data Science Interivews',
    slug: 'coding-guide-for-data-science-interviews',
    image: 'https://miro.medium.com/max/1400/1*F_aU1HGLyUslK8U-THy1nw.jpeg',
    content: require('./coding-guide-data-science-interviews.md'),
  }


]

export default async () => {
  return await Promise.all(POSTS.map(async post => {
    const res = await fetch(post.content)
    const content = await res.text()
    return {
      title: post.title,
      slug: post.slug,
      image: post.image,
      content: content,
    }
  }))
}
