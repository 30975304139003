import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { initializeAdTracking } from '@/analytics';
import queryString from 'query-string';

import { STRIPE_KEY } from '@/constants';
import cookieManager from '@/lib/cookieManager'

import PricingBoxes from '@/components/PricingBoxes';
import Checkout from '@/components/Checkout';
import CouponNotification from '@/components/CouponNotification';
import BookLink from '@/components/BookLink';
import HowItWorks from '@/components/HowItWorks';
import SuccessStories from '@/components/SuccessStories';
import FrequentlyAskedQuestions from '@/components/FrequentlyAskedQuestions';

import '@/main.scss';

export default class Upgrade extends Component {
  state = {
    plan: null,
    coupon: null,
    attemptPayment: false,
  }
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    initializeAdTracking(this.props.location.pathname)

    // Get coupon code from query param, check if it's valid.
    const params = queryString.parse(this.props.location.search)
    this.checkCouponCode(params.coupon)
  }
  checkCouponCode = async (code) => {
    // If it's valid, then show marked down success notif + marked down prices
    const res = await fetch(`/api/payments/coupon/${code}`)
    const body = await res.json()
    if (body.auth) {
      this.setState({ coupon: body.coupon })
    }
  }
  toggleSubscription = (type) => {
    this.setState({
      plan: type
    });
  }
  closeModal = () => {
    this.setState({
      plan: null
    });
  }
  handleAttemptPayment = () => {
    this.setState({
      attemptPayment: true
    });
  }
  onCheckout = async (email, token) => {
    if (token.error) {
      this.setState({
        error: token.error.message,
        attemptPayment: false,
      });
    } else {
      const params = {
        email: email,
        paymentToken: token.token.id,
        plan: this.state.plan
      }
      const ref = cookieManager.getRef()
      if (ref != null) {
        params.ref = ref
      }
      if (this.state.coupon != null) {
        params.coupon = this.state.coupon.code
      }
      const UPGRADE_RESPONSE = await fetch('/api/payments/upgrade/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify(params)
      });
      const UPGRADE_DATA = await UPGRADE_RESPONSE.json();

      if(UPGRADE_DATA.auth) {
        this.props.history.push('/upgrade/success');
      } else {
        this.setState({
          error: UPGRADE_DATA.error,
          attemptPayment: false,
        });
      }
    }
  }
  render() {
    const params = queryString.parse(this.props.location.search)

    let buttonText
    if (this.state.coupon != null && this.state.plan != null) {
      buttonText = `Pay $${this.state.coupon.price[this.state.plan].final / 100}`
    } else {
      buttonText = `Pay ` + (this.state.plan === 'yearly' ? '$95' : '$12')
    }

    return (
      <StripeProvider apiKey = {STRIPE_KEY}>
        <div className = 'mainContainer'>
          {
            this.state.plan ?
            (
              <Checkout
                error = {this.state.error}
                closeModal = {this.closeModal}
                onCheckout = {this.onCheckout}
                initialEmail = {params.email}
                subtitle = {this.state.plan === 'yearly' ? 'Yearly Subscription' : 'Monthly Subscription'}
                buttonText = {buttonText}
              />
            ) :
            (
              <div />
            )
          }
          <div className = 'mainSection'>
            <div className = 'heroSection' id = 'subscribe'>
              <h2>Free subscribers only get problems, without the solutions...</h2>
              <h1 className='serif'>Upgrade to premium and get solutions!</h1>
            </div>
          </div>
          <BookLink />
          <CouponNotification coupon = {this.state.coupon} />
          <PricingBoxes
            coupon={this.state.coupon}
            toggleSubscription={this.toggleSubscription}
          />
          <HowItWorks />

          <SuccessStories />
          <FrequentlyAskedQuestions />
          <div className = 'mainSection'>
            <span className = 'ctaText'><strong>Your transactions are secure.</strong> This site is encrypted using a secure connection and all payments are processed through Stripe.com, the same payment processor used by Facebook and over 100,000+ other online businesses.</span>
          </div>
        </div>
      </StripeProvider>
    );
  }
}
