import Cookies from 'js-cookie'

class CookieManager {
  setRef(ref) {
    if (ref != null) {
      Cookies.set('ref', ref, { expires: 7 })
    }
  }
  getRef() {
    return Cookies.get('ref')
  }
}

export default new CookieManager()
