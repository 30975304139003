import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { initializeAdTracking } from '@/analytics';
import UnsubscribeModal from '@/components/UnsubscribeModal';
import SnoozeModal from '@/components/SnoozeModal';
import Spinner from '@/components/Spinner';
import '@/main.scss';
import './index.scss'

export default class Unsubscribe extends Component {
  state = {
    loading: true,
    snoozeOrUnsubscribeLoading: false,
    unsubscribeSuccess: false,
    unsubscribeError: null,
    snoozeSuccess: false,
    snoozeError: null,
    snoozeFor: null,
    email: null
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    if(this.props.history.location.search){
      let params = await new URLSearchParams(this.props.history.location.search);
      this.setState({
        token: params.get('token')
      });
      const VERIFICATION_RESPONSE = await fetch('/api/users/unsubscribe/' + this.state.token, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const VERIFICATION_DATA = await VERIFICATION_RESPONSE.json();
      if(VERIFICATION_DATA.auth){
        this.setState({
          email: VERIFICATION_DATA.email
        });
      }
      else{
        this.setState({
          email: null
        });
      }
    }
    this.setState({
      loading: false
    });
  }
  unsubscribe = async () => {
    this.setState({ snoozeOrUnsubscribeLoading: true })
    if(this.state.email){
      const UNSUBSCRIBE_RESPONSE = await fetch('/api/users/unsubscribe/' + this.state.token + '/' + this.state.email, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const UNSUBSCRIBE_DATA = await UNSUBSCRIBE_RESPONSE.json();
      if(UNSUBSCRIBE_DATA.auth){
        this.setState({
          unsubscribeSuccess: UNSUBSCRIBE_DATA.auth
        });
      }
      else{
        this.setState({
          unsubscribeError: UNSUBSCRIBE_DATA.error
        });
      }
    }
    this.setState({ snoozeOrUnsubscribeLoading: false })
  }
  snooze = async (snoozeFor) => {
    this.setState({ snoozeOrUnsubscribeLoading: true })
    if (this.state.email) {
      const SNOOZE_RESPONSE = await fetch('/api/users/snooze/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify({
          token: this.state.token,
          email: this.state.email,
          snoozeFor,
        })
      });
      const SNOOZE_DATA = await SNOOZE_RESPONSE.json();
      if (SNOOZE_DATA.auth){
        this.setState({
          snoozeSuccess: SNOOZE_DATA.auth,
          snoozeFor,
        });
      }
      else{
        this.setState({
          snoozeError: SNOOZE_DATA.error
        });
      }
    }
    this.setState({ snoozeOrUnsubscribeLoading: false })
  }
  render() {
    if(this.state.loading){
      return (
        <div>
          <Spinner />
        </div>
      );
    }
    else{
      return (
        <div className = 'mainContainer'>
          <UnsubscribeModal
            error = {this.state.unsubscribeError}
            success = {this.state.unsubscribeSuccess}
            closeModal = {() => this.setState({ unsubscribeError: null, unsubscribeSuccess: false })}
          />
          <SnoozeModal
            error = {this.state.snoozeError}
            snoozeFor = {this.state.snoozeFor}
            success = {this.state.snoozeSuccess}
            closeModal = {() => this.setState({ snoozeError: null, snoozeSuccess: false })}
          />
          {
            this.state.email ?
            (
              <div className = 'verifyContainer'>
                <div className = 'verifySection'>
                  <h1 className = 'verifyTitle'>Update email preferences for {this.state.email}</h1>
                </div>
                <div className = 'verifySection'>
                  <h3 className = 'verifyHeader'>Please Note!</h3>
                  <p className = 'verifyText'>If you're subscribed to Premium, <strong>this does not stop your plan</strong>. To stop your plan, please email us at <a href = 'mailto:team@datascienceprep.com' className = 'linkText'>team@datascienceprep.com</a>.</p>
                </div>
                {this.state.snoozeOrUnsubscribeLoading ?
                  <Spinner />
                  :
                  <div className = 'verifySection button-container'>
                    <button className = 'modalButton' onClick = {() => this.snooze('1 week')}>Snooze for a week</button>
                    <button className = 'modalButton' onClick = {() => this.snooze('1 month')}>Snooze for 1 month</button>
                    <button className = 'modalButton' onClick = {() => this.snooze('3 months')}>Snooze for 3 months</button>
                    <button className = 'modalButton' onClick = {() => this.snooze('6 months')}>Snooze for 6 months</button>
                    <span>or</span>
                    <button className = 'modalButton red' onClick = {() => this.unsubscribe()}>Unsubscribe</button>
                  </div>}
              </div>
            ) :
            (
              <div className = 'verifyContainer'>
                <div className = 'verifySection'>
                  <h1 className = 'verifyTitle'>Sorry, we couldn't find your email.</h1>
                </div>
                <div className = 'verifySection'>
                  <h3 className = 'verifyHeader'>Try again or contact support</h3>
                  <p className = 'verifyText'>To contact us, please email us at <a href = 'mailto:team@datascienceprep.com' className = 'linkText'>team@datascienceprep.com</a>.</p>
                </div>
                <div className = 'goBack'>
                  <NavLink to = '/'>
                    <button className = 'modalButton'>Go back</button>
                  </NavLink>
                </div>
              </div>
            )
          }
          </div>
      );
    }
  }
}
