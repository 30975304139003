import React, { Component } from 'react'
import { StripeProvider } from 'react-stripe-elements';

import Spinner from '@/components/Spinner'
import Checkout from '@/components/Checkout'
import UpdateCardModal from '@/components/UpdateCardModal'
import SaveModal from '@/components/SaveModal'
import { STORAGE_KEY, STRIPE_KEY } from '@/constants'
import './index.scss'

export default class Settings extends Component {
  state = {
    user: null,
    error: null,
    loading: true,
    newEmail: '',
    showCheckout: false,
    showUpdateCardModal: false,
    showSaveModal: false,
  }
  async componentDidMount() {
    this.fetchUser()
  }
  fetchUser = async () => {
    const token = await localStorage.getItem(STORAGE_KEY)
    if (token == null) {
      this.setState({
        user: null,
        loading: false,
        error: 'You are not logged in.'
      });
      // TODO: redirect to landing page?
      return
    }

    try {
      const res = await fetch(`/api/users/`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
          'x-access-token': token
        }
      })
      const body = await res.json()
      if (body.auth) {
        this.setState({
          user: body.user,
          loading: false,
          newEmail: body.user.email,
        });
      } else {
        this.setState({
          error: body.error,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({ error: e })
    }
  }
  handleEmailChanged = (e) => {
    this.setState({ newEmail: e.target.value })
  }
  updateCard = () => {
    this.setState({ showCheckout: true })
  }
  onCheckout = async (email, token) => {
    const bearerToken = await localStorage.getItem(STORAGE_KEY)

    // Update account with new stripe source
    const res = await fetch('/api/users/', {
      method: 'put',
      headers: {
        'Authorization': 'Bearer ' + bearerToken,
        'x-access-token': bearerToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stripeSource: token.token.id })
    })
    const body = await res.json()
    if (body.auth) {
      this.fetchUser()
      this.setState({ showCheckout: false, showUpdateCardModal: true })
    } else {
      this.setState({ error: body.error })
    }
  }
  save = async () => {
    const bearerToken = await localStorage.getItem(STORAGE_KEY)

    // Update account with new email
    const res = await fetch('/api/users/', {
      method: 'put',
      headers: {
        'Authorization': 'Bearer ' + bearerToken,
        'x-access-token': bearerToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: this.state.newEmail })
    })
    const body = await res.json()
    if (body.auth) {
      this.fetchUser()
      this.setState({ showCheckout: false, showSaveModal: true })
    } else {
      this.setState({ error: body.error })
    }
  }
  render() {
    const { loading, user, error } = this.state
    if (loading) {
      return (<div className = 'stateContainer settings'>
        <Spinner />
      </div>)
    }

    if (error) {
      return (<div className = 'stateContainer settings'>
        <h3>{error}</h3>
      </div>)
    }

    return (
      <StripeProvider apiKey = {STRIPE_KEY}>
        <div className = 'stateContainer'>
          { this.state.showCheckout ?
              <Checkout
                initialEmail = {user.email}
                hideEmailField = {true}
                closeModal = {() => this.setState({ showCheckout: false })}
                subtitle = {`Update Card for ${user.email}`}
                buttonText = 'Update Card'
                error = {this.state.error}
                onCheckout = {this.onCheckout}
              />
              :
              <div />
          }
          <UpdateCardModal show={this.state.showUpdateCardModal} closeModal = {() => this.setState({ showUpdateCardModal: false })} />
          <SaveModal show={this.state.showSaveModal} closeModal = {() => this.setState({ showSaveModal: false })} />
          <div className='stateContainer settings'>
            <h3>Settings</h3>
            <div className='settings-form'>
              <label>Email</label>
              <input value={this.state.newEmail} onChange={this.handleEmailChanged} />

              <label>Membership</label>
              {user.type === 'premium'
                ? <div className='badge'><span>PREMIUM</span></div>
                : <div className='badge free'><span>FREE</span></div>
              }

              {user.type === 'premium'
                ? <React.Fragment>
                <label>Billing</label>
                <div>
                  <span>{user.billing.brand} ···· ···· ···· {user.billing.last4} {user.billing.exp_month} / {user.billing.exp_year}</span>
                  <span className='update-card-button' onClick={this.updateCard}>Update Card</span>
                </div>
              </React.Fragment>
                : null
              }
            </div>

            <button onClick={this.save} className='cta'>Save</button>
          </div>
        </div>
      </StripeProvider>
    );
  }
}
