import React, { Component } from 'react';
import classNames from 'classnames'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import DotLoader from '@/components/DotLoader';
import Logo from '@/assets/logos/logo.svg';
import Close from '@/assets/iconography/close.svg';
import validator from 'validator';

import './index.scss';

class CheckoutForm extends Component {
  state = {
    email: '',
    attemptingPayment: false,
  }
  componentDidMount() {
    if (this.props.initialEmail != null && validator.isEmail(this.props.initialEmail)) {
      this.setState({ email: this.props.initialEmail })
    }
  }
  onSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ attemptingPayment: true })
    const token = await this.props.stripe.createToken({ type: 'card' });
    if (token) {
      await this.props.onCheckout(this.state.email, token);
    }
    this.setState({ attemptingPayment: false })
  }
  render() {
    return (
      <div className = 'paymentContainer'>
        <form className = 'paymentSection animated fadeIn' onSubmit = {this.onSubmit}>
          <div className = 'paymentHeader'>
            <div className = 'paymentClose' onClick = {() => this.props.closeModal()}>
              <img src = {Close} className = 'closeIcon' alt = 'Data Science Prep' />
            </div>
            <div className = 'paymentLogoContainer'>
              <img src = {Logo} className = 'paymentLogo' alt = 'Ace your data science interview with data science prep questions sent to your inbox. Material is prepared by data scientists who received offers from Facebook, Google, Amazon, and much more. Data science questions specifically asked at big companies like Twitter, Yelp, Asana, and more top data science tech companies.' />
            </div>
            <span className = 'paymentTitle'>Data Science Prep</span>
            <span className = 'paymentSubtitle'>{this.props.subtitle}</span>
          </div>
          {
            this.state.attemptingPayment ?
            (
              <div className = 'paymentEntry paymentLoader'>
                <DotLoader />
                <p className = 'paymentLoaderText'>Subscribing...</p>
              </div>
            ) :
              null
          }
          <div className = {classNames('paymentEntry', { 'hidden': this.state.attemptingPayment })}>
            <input className = {classNames('paymentInput', { 'hidden': this.props.hideEmailField } )} type = 'email' onChange = {(e) => this.setState({ email : e.target.value })} value={this.state.email} placeholder = 'Email' required = 'required' />
            <div className = 'cardInfo'>
              <CardNumberElement className = 'cardNumber' />
              <div className = 'cardAdditional'>
                <CardExpiryElement className = 'cardExpiry' />
                <CardCVCElement className = 'cardCVC' />
              </div>
            </div>
            <div className = 'errorSection'>
              <span className = 'errorText'>{this.props.error}</span>
            </div>
            {
              this.state.attemptingPayment ?
              (
                <button disabled className = 'paymentButton'>{this.props.buttonText}</button>
              ) :
              (
                <button className = 'paymentButton'>{this.props.buttonText}</button>
              )
            }
          </div>
        </form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
