import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Spinner from '@/components/Spinner';

class Logout extends Component {
  componentDidMount = async () => {
    await this.props.resetToken();
    this.props.history.push('/');
  }
  render() {
    return (
      <Spinner />
    );
  }
}

export default withRouter(Logout);