import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';

import Spinner from '@/components/Spinner'

import '@/main.scss';
import './index.css';

class FormLogin extends Component {
  state = {
    email: null,
    password: null,
    loggingIn: false,
    error: null,
  }
  login = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      loggingIn: true,
    });
    if(!this.state.password || !this.state.email){
      this.setState({
        error: 'Please enter all required fields',
        loggingIn: false,
      });
    }
    else {
      const LOGIN_RESPONSE = await fetch(`/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        })
      });
      const LOGIN_DATA = await LOGIN_RESPONSE.json();

      if(LOGIN_DATA.auth){
        this.setState({
          success: true,
          error: null,
        });
        await this.props.handleLogin(LOGIN_DATA.authToken);
        setTimeout(() => {
          this.props.history.push('/dashboard');
        }, 1500);
      }
      else{
        this.setState({
          error: LOGIN_DATA.error,
          loggingIn: false,
        });
      }
    }
  }
  render() {
    if(this.state.loggingIn){
      return <Spinner />
    }
    return (
      <form onSubmit = {this.login}>
        <div className = 'formSection'>
          <div className = 'formField'>
            <label className = 'formLabel'>Email</label>
            <input
              onChange = {(e) => this.setState({ email : e.target.value })}
              type = 'email'
              placeholder = 'Email'
              value = {this.state.email}
              className = 'formInput'
            />
          </div>
          <div className = 'formField'>
            <label className = 'formLabel'>Password</label>
            <input
              onChange = {(e) => this.setState({ password : e.target.value })}
              type = 'password'
              placeholder = 'Password'
              value = {this.state.password}
              className = 'formInput'
            />
          </div>
        </div>
        <div className = 'formSection'>
          <span className = 'error'>{this.state.error}</span>
          <button>Login</button>
        </div>
        <div className = 'forgotPassword'>
          <NavLink to = '/reset-password' className = 'forgotPasswordText'>Forgot Password?</NavLink>
        </div>
      </form>
    );
  }
}

export default withRouter(FormLogin);
