import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { initializeAdTracking } from '@/analytics';
import PostCard from '@/components/PostCard';
import Spinner from '@/components/Spinner';
import './index.scss';

import getPosts from '@/posts'

export default class Blog extends Component {
  state = {
    posts: null
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const posts = await getPosts()
    this.setState({ posts })
  }
  render() {
    if(this.state.posts == null){
      return <Spinner />
    }
    return (
      <div className = 'blogContainer'>
        {
          this.state.posts.map(post => {
            return (
              <PostCard post = {post} />
            )
          })
        }
        <div className = 'blogCTAContainer'>
          <h1 className = 'blogCTAHeader'>Ace your data science interview</h1>
          <h2 className = 'blogCTASubtitle'>Start thinking like a data scientist by getting data science interview questions in your inbox.</h2>
          <div>
            <NavLink smooth to = '/'>
              <button className = 'blogCTA'>Subscribe</button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
