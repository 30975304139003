import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { initializeAdTracking } from '@/analytics';
import Checkmark from '@/assets/iconography/checkmark.svg';
import ErrorIcon from '@/assets/iconography/error.svg';
import Spinner from '@/components/Spinner';
import '@/main.scss';

export default class Verification extends Component {
  state = {
    loading: true,
    success: false,
    copied: false,
    shareLink: 'https://datascienceprep.com/?utm_source=share&utm_medium=link&utm_campaign=friend_referral'
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    if(this.props.history.location.search){
      let params = await new URLSearchParams(this.props.history.location.search);
      this.setState({
        token: params.get('token')
      });
      const VERIFICATION_RESPONSE = await fetch('/api/users/verification/' + this.state.token, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const VERIFICATION_DATA = await VERIFICATION_RESPONSE.json();
      if(VERIFICATION_DATA.auth){
        this.setState({
          success: true
        });
      }
      else{
        this.setState({
          success: false
        });
      }
    }
    this.setState({
      loading: false
    });
  }
  onCopy = () => {
    this.setState({
      copied: true
    });
    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1000);
  }
  render() {
    if(this.state.loading){
      return (
        <div>
          <Spinner />
        </div>
      );
    }
    else{
      return (
        <div className = 'mainContainer'>
          <div className = 'verifyContainer'>
            {
              this.state.success ?
              (
                <div className = 'verifySection'>
                  <img src = {Checkmark} className = 'checkmark' alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
                  <h1 className = 'verifyTitle'>Thank you for confirming your subscription!</h1>
                </div>
              ) :
              (
                <div className = 'verifySection'>
                  <img src = {ErrorIcon} className = 'checkmark' alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
                  <h1 className = 'verifyTitle'>Sorry, there was a problem verifying your email.</h1>
                </div>
              )
            }

            <div className = 'verifySection'>
              <h3 className = 'verifyHeader'>Have questions?</h3>
              <p className = 'verifyText'><NavLink to = '/upgrade#faq' className = 'linkText'>Click here</NavLink> to visit our FAQ page.</p>
            </div>

            <div className = 'verifySection'>
              <NavLink to = '/upgrade#pricing' className = 'navButton'>
                <button>Learn more about premium</button>
              </NavLink>
            </div>

            <div className = 'separator' />

            <div className = 'verifySection'>
              <h3 className = 'verifyHeader'>Share us with your friends</h3>
              <p className = 'verifyText'>Help your friends and colleagues ace their data science interview.</p>
            </div>
            {
              this.state.copied ?
              (
                <div className = 'shareContainer copied'>
                  <span className = 'shareLink copied'>https://datascienceprep.com</span>
                  <img className = 'shareCTA copied' src = {Checkmark} alt = 'Get tailored data science interview prep questions in your inbox. Get data science jobs at Facebook, Google, Amazon and more.' />
                </div>
              ) :
              (
                <div className = 'shareContainer'>
                  <span className = 'shareLink'>https://datascienceprep.com</span>
                  <CopyToClipboard onCopy = {this.onCopy} text = {this.state.shareLink}>
                    <span className = 'shareCTA'>Copy</span>
                  </CopyToClipboard>
                </div>
              )
            }
          </div>
        </div>
      );
    }
  }
}
