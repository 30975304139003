import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';

export default class NotFound extends Component {
  render() {
    return (
      <div className = 'stateContainer'>
        <NavLink to = '/' className = 'stateText'>Looks like you're lost, click here to go back to the main page</NavLink>
      </div>
    );
  }
}
