import React, { Component } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import validator from 'validator'
import { initializeAdTracking } from '@/analytics'

import cookieManager from '@/lib/cookieManager'

import SubscribeModal from '@/components/SubscribeModal';
import Hero from '@/components/Hero';
import SampleProblem from '@/components/SampleProblem';
import HowItWorks from '@/components/HowItWorks';
import SuccessStories from '@/components/SuccessStories';
import Companies from '@/components/Companies'

import '@/main.scss'

export default class Splash extends Component {
  state = {
    attemptingEnrollment: false,
    error: null,
    success: false,
    email: '',
    problem: '',
    solution: '',
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const SOLUTION_RESPONSE = await fetch('/api/solutions/sample', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const SOLUTION_DATA = await SOLUTION_RESPONSE.json();
    if(SOLUTION_DATA.auth){
      this.setState({
        title: SOLUTION_DATA.title,
        problem: SOLUTION_DATA.problem,
        solution: SOLUTION_DATA.solution,
      });
    }
  }
  enroll = async (ev) => {
    ev.preventDefault();
    this.setState({
      attemptingEnrollment: true
    });
    if (!validator.isEmail(this.state.email)) {
      this.setState({
        error: 'This is not a valid email.',
        attemptingEnrollment: false
      });
      return
    }

    const params = {
      email: this.state.email
    }
    const ref = cookieManager.getRef()
    if (ref != null) {
      params.ref = ref
    }
    const res = await fetch('/api/users/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
      body: JSON.stringify(params)
    });
    const { auth, error } = await res.json();
    if (auth) {
      this.setState({
        success: true,
        error: null,
        attemptingEnrollment: false
      });
    } else {
      this.setState({
        error: error,
        attemptingEnrollment: false
      });
    }
  }
  resubscribe = async () => {
    const RESUBSCRIBE_RESPONSE = await fetch('/api/users/resubscribe/' + this.state.email, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const RESUBSCRIBE_DATA = await RESUBSCRIBE_RESPONSE.json();
    if(RESUBSCRIBE_DATA.auth){
      this.setState({
        success: true,
        error: null
      });
    }
    else{
      this.setState({
        error: RESUBSCRIBE_DATA.error
      });
    }
  }
  closeModal = () => {
    this.setState({
      error: null
    });
  }
  setEmail = (email) => {
    this.setState({
      email: email
    });
  }
  render() {
    return (
      <div className = 'mainContainer'>
        {
          this.state.error || this.state.success ?
          (
            <SubscribeModal
              success = {this.state.success}
              error = {this.state.error}
              closeModal = {this.closeModal}
              resubscribe = {this.resubscribe}
            />
          ) :
          (
            <div />
          )
        }
        <Hero
          setEmail = {this.setEmail}
          enroll = {this.enroll}
          attemptingEnrollment = {this.state.attemptingEnrollment}
        />
        <Companies />
        <HowItWorks />
        <SampleProblem
          title = {this.state.title}
          problem = {this.state.problem}
          solution = {this.state.solution}
        />
        <SuccessStories />
        <div className = 'mainSection'>
          <NavLink smooth to = '/#subscribe' className = 'subscribeCTANav'>
            <button className = 'subscribeCTAButton'>Enter Email To Get Data Science Problems</button>
          </NavLink>
        </div>
      </div>
    );
  }
}
