import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '@/assets/logos/logo.svg';
import './index.scss';

export default class Header extends Component {
  render() {
    if(this.props.globals.type === 'premium'){
      return (
        <div className = 'headerContainer'>
          <div className = 'layoutSection'>
            <div className = 'headerSection'>
              <div className = 'headerItem'>
                <NavLink to = '/' className = 'headerLogoContainer'>
                  <img src = {Logo} className = 'headerLogo' alt = 'Data Science Prep helps you secure a data science job' />
                </NavLink>
                <NavLink to = '/' className = 'wordmark'>
                  <h2 className = 'wordmark'>Data Science Prep</h2>
                </NavLink>
              </div>
              <div className = 'headerItem'>
                <NavLink to = '/dashboard' className = 'blog'>
                  <h2 className = 'blog'>Dashboard</h2>
                </NavLink>
                <NavLink to = '/settings' className = 'blog'>
                  <h2 className = 'blog'>Settings</h2>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className = 'headerContainer'>
        <div className = 'layoutSection'>
          <div className = 'headerSection'>
            <div className = 'headerItem'>
              <NavLink to = '/' className = 'headerLogoContainer'>
                <img src = {Logo} className = 'headerLogo' alt = 'Data Science Prep helps you secure a data science job' />
              </NavLink>
              <NavLink to = '/' className = 'wordmark'>
                <h2 className = 'wordmark'>Data Science Prep</h2>
              </NavLink>
            </div>
            <div className = 'headerItem'>
              <NavLink to = '/upgrade' className = 'blog'>
                <h2 className = 'blog'>Upgrade</h2>
              </NavLink>
              <NavLink to = '/login' className = 'blog'>
                <h2 className = 'blog'>Login</h2>
              </NavLink>
              <NavLink to = '/blog' className = 'blog'>
                <h2 className = 'blog'>Blog</h2>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
