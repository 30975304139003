import React, { Component } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link';

import { initializeAdTracking } from '@/analytics'

import Spinner from '@/components/Spinner'
import Mapper from '@/components/Mapper'

import '@/main.scss'

export default class Dashboard extends Component {
  state = {
    authorizing: true,
    noAuth: true,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    if(this.props.globals && this.props.globals.user && this.props.globals.type === 'premium'){
      this.setState({
        noAuth: false,
        authorizing: false,
      })
    }
    else{
      this.props.resetToken();
      this.setState({
        noAuth: true,
        authorizing: false,
      });
    }
  }
  render() {
    if(this.state.authorizing){
      return <Spinner />
    }
    if(this.state.noAuth){
      return <div className = 'mainContainer'>
        <h3 className='serif'>You must be logged in to a premium account to view this page.</h3>
        <span><NavLink to = '/upgrade/#pricing' className = 'linkText'>Upgrade your account</NavLink> to view solutions or <NavLink to = '/login' className = 'linkText'>login to your account</NavLink> if you're already on premium!</span>
      </div>
    }
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>My Dashboard</h3>
            <h2>Click on any problem to view the solution!</h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <div className = 'mainSectionColumn'>
            <h3 className='serif'>Problems</h3>
            <Mapper user = {this.props.globals.user} type = 'problemTitles' />
          </div>
        </div>
      </div>
    );
  }
}
