import React, { Component } from 'react'

import { initializeAdTracking } from '@/analytics'

import Spinner from '@/components/Spinner'
import FormSetPassword from '@/components/FormSetPassword'

import '@/main.scss'

export default class SetPassword extends Component {
  state = {
    fetchingUser: true,
    email: null,
    password: null,
    confirmPassword: null,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let params = await new URLSearchParams(this.props.history.location.search);
    const token = params.get('token');
    const AUTH_RESPONSE = await fetch(`/api/users/password/${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const AUTH_DATA = await AUTH_RESPONSE.json();
    if(AUTH_DATA.auth) {
      this.setState({
        fetchingUser: false,
        user: AUTH_DATA.user,
        token: token,
      });
    } else {
      this.setState({
        fetchingUser: false,
        error: AUTH_DATA.error,
      });
    }
  }
  render() {
    if(this.state.fetchingUser){
      return <Spinner />
    }
    if(this.state.error){
      console.log(this.state.error)
      return <div className = 'mainContainer'>
        <h3 className='serif'>There was an error loading this page.</h3>
        <h2>Please try again or contact support.</h2>
      </div>
    }
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>Set Password</h3>
            <h2>You're setting the password for your account: <strong>{this.state.user.email}</strong></h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <div className = 'mainSectionColumn'>
            <FormSetPassword token = {this.state.token} handleLogin = {this.props.handleLogin} />
          </div>
        </div>
      </div>
    );
  }
}
