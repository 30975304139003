import React, { Component } from 'react'

import { initializeAdTracking } from '@/analytics'

import FormResetPassword from '@/components/FormResetPassword'

import '@/main.scss'

export default class ResetPassword extends Component {
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>Reset Password</h3>
            <h2>Enter your email below to reset your password.</h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <div className = 'mainSectionColumn'>
            <FormResetPassword />
          </div>
        </div>
      </div>
    );
  }
}
