import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Lock from '@/assets/iconography/lock.svg'
import './index.scss'

export default class PricingBoxes extends Component {
  static propTypes = {
    toggleSubscription: PropTypes.func.isRequired,
    coupon: PropTypes.object,
  }

  render() {
    let monthlyPrice
    let yearlyPrice
    if (this.props.coupon != null) {
      monthlyPrice = <div className='the-price'>
        <h2 className = 'strikeout purple'>${this.props.coupon.price.monthly.strike / 100}</h2>
        <h2 className = 'price monthlyPrice'>${this.props.coupon.price.monthly.final / 100}</h2>
      </div>
      yearlyPrice = <div className='the-price'>
        <h2 className = 'strikeout yellow'>${this.props.coupon.price.yearly.strike / 100}</h2>
        <h2 className = 'price yearlyPrice'>${this.props.coupon.price.yearly.final / 100}</h2>
      </div>
    } else {
      monthlyPrice = <div className='the-price'>
			  <h2 className = 'price monthlyPrice'>$12</h2>
      </div>
      yearlyPrice = <div className='the-price'>
        <h2 className = 'strikeout yellow'>$144</h2>
				<h2 className = 'price yearlyPrice'>$95</h2>
      </div>
    }
    return (
			<div className = 'mainSection'>
				<div className = 'pricingSection' id = 'pricing'>
					<div className = 'pricingSectionInner monthlyPricingSection'>
						<div className = 'pricingHeader monthlyPricingHeader'>
							<span className = 'pricingHeaderText monthlyPricingHeaderText'>Most Popular</span>
						</div>
						<div className = 'pricingContainer'>
							<h4 className = 'pricingType monthlyPricingTypeText'>Monthly</h4>
							<span className = 'billPeriod'>Billed every month.</span>
							<span className = 'currencyType'>Prices in USD</span>
              {monthlyPrice}
							<ul className = 'benefitsSection monthlyPackage'>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Standard package</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Email newsletter with solutions the next day</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Resume tips and best practices</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Tricks & guides to data science problems</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Renews automatically</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Cancel anytime</span>
								</li>
							</ul>
							<button onClick = {() => this.props.toggleSubscription('monthly')}>Sign Up For Solutions</button>
							<span className = 'secureCheckout'><img className = 'secureCheckoutIcon' src = {Lock} alt = 'Data science Prep secure checkout for data science jobs'/>Secure checkout</span>
						</div>
					</div>
					<div className = 'pricingSectionInner yearlyPricingSection'>
						<div className = 'pricingHeader yearlyPricingHeader'>
							<span className = 'pricingHeaderText yearlyPricingHeaderText'>Best Value</span>
						</div>
						<div className = 'pricingContainer'>
							<h4 className = 'pricingType yearlyPricingTypeText'>Yearly</h4>
							<span className = 'billPeriod'>Billed every 12 months.</span>
							<span className = 'currencyType'>Prices in USD</span>
              {yearlyPrice}
							<ul className = 'benefitsSection yearlyPackage'>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>$7.91 / month (33% off!)</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Email newsletter with solutions the next day</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Resume tips and best practices</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Tricks & guides to data science problems</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Renews automatically</span>
								</li>
								<li>
									<span className = 'mark'>✓</span>
									<span className = 'benefitText'>Cancel anytime</span>
								</li>
							</ul>
							<button className = 'subscribeCTAButton' onClick = {() => this.props.toggleSubscription('yearly')}>Sign Up For Solutions</button>
							<span className = 'secureCheckout'><img className = 'secureCheckoutIcon' src = {Lock} alt = 'Data Science Prep secure checkout for data science jobs'/>Secure checkout</span>
						</div>
					</div>
				</div>
			</div>
    );
  }
}
