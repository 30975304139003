import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DotLoader } from 'react-spinners';

import '@/main.scss'

export default class Hero extends Component {
  static propTypes = {
    enroll: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className = 'mainSection'>
        <div className = 'heroSection' id = 'subscribe'>
          <h1 className='serif'>Ace your data science interview</h1>
          <h2>Get exceptionally good at data science interviews by getting real interview questions in your inbox.</h2>
          <form className = 'heroCTASection' onSubmit = {this.props.enroll}>
            <input className = 'emailInput' type = 'email' onChange = {(e) => this.props.setEmail(e.target.value)} placeholder = 'Enter your email' required = 'required' />
            {
              this.props.attemptingEnrollment ?
              (
                <button disabled className = 'heroSubscribeButton'><DotLoader color = {'#6e5817'} size = {24}/></button>
              ) :
              (
                <button className = 'heroSubscribeButton'>Subscribe to Data Science Prep</button>
              )
            }
            <span className = 'ctaText'>No spam, ever. Easy unsubscribe.</span>
          </form>
        </div>
      </div>
    );
  }
}
