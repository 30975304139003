import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import './index.css';

export default class SnoozeModal extends Component {
  render() {
    if (this.props.error == null && this.props.success === false) {
      return null
    }

    if (this.props.error != null) {
      return (
        <div className = 'modalContainer'>
          <div className = 'modalSection'>
            <p className = 'modalTitle'>Error!</p>
            <p className = 'modalText'>{this.props.error}</p>
            <p className = 'modalText'>Please contact us at <a href = 'mailto:team@datascienceprep.com' className = 'linkText'>team@datascienceprep.com</a> and let us know what's going on.</p>
            <button className = 'modalButton' onClick = {() => this.props.closeModal()}>Close</button>
          </div>
        </div>
      );
    }

    return (
      <div className = 'modalContainer'>
        <div className = 'modalSection'>
          <p className = 'modalTitle'>You've snoozed your emails!</p>
          <p className = 'modalText'>You'll start receiving emails again in {this.props.snoozeFor}.</p>
          <p className = 'modalText'>Made a mistake? You can resubscribe here: <NavLink to = '/' className = 'linkText'>resubscribe</NavLink></p>
          <NavLink to = '/'>
            <button className = 'modalButton'>Close</button>
          </NavLink>
        </div>
      </div>
    );
  }
}
