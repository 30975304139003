import React, { Component } from 'react'
import { initializeAdTracking } from '@/analytics'
import { STORAGE_KEY } from '@/constants';
import Spinner from '@/components/Spinner'
import ProblemAndSolution from '@/components/ProblemAndSolution'
import './index.css'

export default class Solution extends Component {
  state = {
    loadingProblem: true,
    loadingSolution: false,
    title: null,
    problem: null,
    solution: null,
    problemNumber: null,
    email: null,
    error: null,
    showSolution: false
  }
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      initializeAdTracking(this.props.location.pathname)
      const problemNumber = this.props.match.params.problemNumber
      const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
      let params = await new URLSearchParams(this.props.history.location.search)
      const token = params.get('token')

      if(token){
        const res = await fetch(`/api/solutions/${problemNumber}/${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const { solution, title, problem } = await res.json()

        // If user is not premium solution will be null
        const email = params.get('email')
        this.setState({
          loadingProblem: false,
          problemNumber,
          email,
          title,
          problem,
          solution
        })
      }
      else{
        const res = await fetch(`/api/solutions/auth/${problemNumber}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + DEMO_TOKEN,
            'x-access-token': DEMO_TOKEN
          }
        })
        const { solution, title, problem } = await res.json()

        // If user isn't premium or if user isn't at this problem yet, solution will be null
        const email = params.get('email')
        this.setState({
          loadingProblem: false,
          problemNumber,
          email,
          title,
          problem,
          solution
        })
      }
    } catch (e) {
      console.log(e)
      this.setState({
        loadingProblem: false,
        error: e
      })
    }
  }
  render() {
    if (this.state.loadingProblem) {
      return <Spinner />
    }
    if (this.state.error) {
      console.log(this.state.error)
      return <div className = 'mainContainer'>
        <h3 className='serif'>There was an error loading this problem.</h3>
      </div>
    }
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>Data Science Prep Problem #{this.state.problemNumber}</h3>
            <h2>{this.state.title}</h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <div className = 'mainSectionColumn'>
            <h3 className='serif'>Problem</h3>
            <ProblemAndSolution
              problem = {this.state.problem}
              solution = {this.state.solution}
              problemNumber = {this.state.problemNumber}
              email = {this.state.email}
            />
          </div>
        </div>
      </div>
    );
  }
}
