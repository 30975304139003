import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';

export default class Footer extends Component {
  render() {
    return (
      <div className = 'footerContainer'>
        <NavLink to = '/' className = 'footerNav'>© Data Science Prep 2021</NavLink>
        <NavLink to = '/privacy' className = 'footerNav'>Privacy Policy</NavLink>
        <NavLink to = '/terms-of-service' className = 'footerNav'>Terms of Service</NavLink>
      </div>
    );
  }
}
