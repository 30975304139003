import React, { Component } from 'react'
import { initializeAdTracking } from '@/analytics'
import Spinner from '@/components/Spinner'
import SurveyForm from '@/components/SurveyForm'
import './index.css'

export default class Survey extends Component {
  state = {
    loadingSurvey: false,
    surveyNumber: null,
    survey: null,
    title: null,
    email: null,
    completed: null,
    error: null,
  }
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      initializeAdTracking(this.props.location.pathname)
      const surveyNumber = this.props.match.params.surveyNumber

      let params = await new URLSearchParams(this.props.history.location.search)
      const token = params.get('token')
      const res = await fetch(`/api/surveys/${surveyNumber}/${token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const { title, survey, completed } = await res.json()

      const email = params.get('email')
      this.setState({
        loadingSurvey: false,
        surveyNumber,
        email,
        title,
        survey,
        completed,
      })
    } catch (e) {
      console.log(e)
      this.setState({
        loadingSurvey: false,
        error: e
      })
    }
  }
  render() {
    if (this.state.loadingSurvey) {
      return <Spinner />
    }
    if (this.state.error) {
      console.log(this.state.error)
      return <div className = 'mainContainer'>
        <h3 className='serif'>There was an error loading this survey.</h3>
      </div>
    }
    if (this.state.completed) {
      return <div className = 'mainContainer'>
        <h3 className='serif'>You've already completed this survey. Thanks again for your help!</h3>
      </div>
    }
    return (
      <div className = 'mainContainer'>
        <div className = 'mainSection'>
          <div className = 'heroSection'>
            <h3 className='serif'>We'd love your help! This should take less than 5 minutes.</h3>
            <h2>Data Science Prep {this.state.title}</h2>
          </div>
        </div>
        <div className = 'mainSection'>
          <SurveyForm
            survey = {this.state.survey}
            surveyNumber = {this.state.surveyNumber}
            email = {this.state.email}
          />
        </div>
      </div>
    );
  }
}
