import React from 'react';
import FacebookWordmark from '@/assets/logos/facebook-wordmark.svg'
import AirbnbWordmark from '@/assets/logos/airbnb-wordmark.svg'
import QuoraWordmark from '@/assets/logos/quora-wordmark.svg'

import Jeff from '@/assets/avatars/jeff.jpg'
import Tina from '@/assets/avatars/tina.jpg'
import Alex from '@/assets/avatars/alex.jpg'

import './index.scss'


export default () => {
  return (
      <div className = 'mainSection'>
        <div className = 'mainSectionColumnTransparent'>
          <h3 className = 'successStoriesHeader serif'>Success Stories</h3>
          <div className = 'successStoriesSection'>
            <div className = 'testimonial'>
              <img className = 'avatar' src = {Tina} alt = 'Tina satisfied Data Science Prep customer data scientist at Facebook' />
              <div className = 'testimonialHeader'>
                <span className = 'testimonialCustomer'>Tina</span>
                <span className = 'testimonialPlus'>+</span>
                <img src = {FacebookWordmark} className = 'facebook' alt = 'Facebook data science interview questions' />
              </div>
              <p className = 'testimonialText'><strong>I landed my dream job at Facebook.</strong> The questions and solutions were extremely helpful, and I even saw some of them in my interviews. So happy I made this investment and thank you for the great resource!</p>
            </div>
            <div className = 'testimonial'>
              <img className = 'avatar' src = {Jeff} alt = 'Jeff satisfied Data Science Prep customer data scientist at Airbnb' />
              <div className = 'testimonialHeader'>
                <span className = 'testimonialCustomer'>Jeff</span>
                <span className = 'testimonialPlus'>+</span>
                <img src = {AirbnbWordmark} className = 'amazon' alt = 'Facebook data science interview questions' />
              </div>
              <p className = 'testimonialText'>Data Science Prep made the all the difference in terms of interview prep. <strong>I got an offer from Airbnb and Microsoft thanks to you guys!</strong></p>
            </div>
            <div className = 'testimonial'>
              <img className = 'avatar' src = {Alex} alt = 'Alex satisfied Data Science Prep customer data scientist at Quora' />
              <div className = 'testimonialHeader'>
                <span className = 'testimonialCustomer'>Alex</span>
                <span className = 'testimonialPlus'>+</span>
                <img src = {QuoraWordmark} className = 'quora' alt = 'Quora data science questions' />
              </div>
              <p className = 'testimonialText'><strong>This was an awesome way to practice for data science interviews.</strong> The questions were super relevant and actually showed up in my interviews a bunch, so I was well-prepared.</p>
            </div>
          </div>
        </div>
      </div>
  );
}
