import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

export default () => {
  return (
    <div className = 'mainSection'>
      <div className = 'mainSectionColumn' id = 'faq'>
        <h3 className='serif'>Frequently Asked Questions</h3>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>Do you have questions from Google / Facebook / Microsoft / Amazon?</h5>
          <p className = 'faqText'>Every single one of our questions have been asked in an actual interview from a top-tier tech company. They are also among the more difficult problems that are asked—our philosophy is that it's better to be overprepared than underprepared. With our subscription, you'll get an in-depth explanation and solution for each hard problem the day after we send it.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>I don't have experience as a data scientist. Should I still subscribe to Data Science Prep?</h5>
          <p className = 'faqText'>While the problems we send rely on knowledge of basic management principles, it is not necessary to have experience. In fact <strong>many of our subscribers are here to enter into data science.</strong> Some concepts may initially be unfamiliar, however we do our best to explain any new or complex concepts. Within only a few weeks, you'll be thinking like a data scientist!</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>Why not Cracking the Data Science Interview?</h5>
          <p className = 'faqText'>We found full-time students and workers are too busy to slog through a gigantic list of questions or dedicate full-time hours to a bootcamp. With our mailing list, you get consistent practice throughout your prepping. Consistent practice is the best way to learn. We also make sure the questions are carefully tailored to match the difficulty of actual interview problems asked at top-tier companies. Finally, getting a problem each day and then the solution the day after gives you a day to think about the question during idle periods of time, which helps with understanding and retention.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>What if I need to cram or want instant problem access?</h5>
          <p className = 'faqText'>With the above being said, since we get this question a lot, we have also added bundles for questions! Check above for details on Volume I.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>How long should I spend solving each problem?</h5>
          <p className = 'faqText'>Each problem is designed to be able to be solved in 30 to 45 minutes in a real-life interview.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>Is Data Science Prep really worth it?</h5>
          <p className = 'faqText'>The typical FAANG compensation for data scientists can be north of $120,000 in base compensation alone. Several months of subscription, or a bundle worth of questions to help you get there? If you do an expected value analysis, we think absolutely!</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>How do I unsubscribe?</h5>
          <p className = 'faqText'>If you'd like to unsubscribe, hopefully it's because you've landed your dream job and no longer need to practice for interviews. Just email us at <span className = 'linkText'>team@datascienceprep.com</span> and your subscription will be cancelled at the end of the subscription period. You'll still get questions until then.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>What topics are covered in Data Science Prep?</h5>
          <p className = 'faqText'>We have the following areas we pick from. However, the questions will be chosen with a distribution that's more aligned with what you get in the actual interviews.</p>
          <ul className = 'faqList'>
            <li className = 'faqText'>Probability</li>
            <li className = 'faqText'>Statistics</li>
            <li className = 'faqText'>Product</li>
            <li className = 'faqText'>Machine Learning</li>
            <li className = 'faqText'>Coding</li>
          </ul>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>If I'm subscribed to the free version of Data Science Prep and I upgrade, will I get solutions for the previous questions?</h5>
          <p className = 'faqText'>Yes! When you subscribe you'll get access to solutions for all the previous questions you received.</p>
        </div>
        <div className = 'faq'>
          <h5 className = 'faqHeader'>I subscribed but I'm not receiving emails.</h5>
          <p className = 'faqText'>If you haven't immediately received an email after signing up, please check your spam folder and make sure our emails haven't gone there. If that's the case, then please whitelist our email to make sure it doesn't go into spam in the future. If that's not the case, please email us at <span className = 'linkText'>team@datascienceprep.com</span> and we'll resolve the issue.</p>
        </div>
        <NavLink smooth to = '/upgrade/#pricing' className = 'subscribeCTANav'>
          <button className = 'subscribeCTAButton'>See Prices And Upgrade</button>
        </NavLink>
      </div>
    </div>
  );
}
